import "./app.css";
import Layout from "./components/Layout/Layout";
import { Toaster } from "react-hot-toast";
import { Web3Provider } from "./components/Context/Web3Context.js";

function App() {
    return (
        <section>
            <Web3Provider>
                <Layout />;
                <Toaster position="top-right" containerStyle={{ marginTop: "65px" }} />
            </Web3Provider>
        </section>
    );
}

export default App;
