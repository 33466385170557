import React from "react";

import Dashboard from "../components/ui/Dashboard";

import LiveAuction from "../components/ui/Live-auction/LiveAuction";

const Home = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <>
      <Dashboard />
      <LiveAuction />
    </>
  );
};

export default Home;
