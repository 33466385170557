import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import NotFound from "./NotFound";
import LoadingSpinner from "../Effect/LoadingSpinner.jsx";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { gradientStyle } from "../assets/data/data";
import "../styles/my-nft-details.css";
// import detectEthereumProvider from "@metamask/detect-provider";
// import Web3 from "web3";
import { abiERC20, abiERC721, abiERC1155, addressKPET, addressNFT, addressGEM } from "../contract/data";
import toast from "react-hot-toast";
import Web3Context from "../components/Context/Web3Context.js";
import { API_URL } from "../configs/constant";

const MyNftDetails = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const statusAuction = { true: ["Ajust", "ri-edit-line"], false: ["List", "ri-price-tag-3-line"] };
    const statusAuction2 = { true: ["Cancel", "ri-close-line"], false: ["Level Up", "ri-arrow-up-line"] };
    const [shouldReload, reload] = useState(false);
    const [loading, setLoading] = useState(false);
    const reloadEffect = () => reload(!shouldReload);
    const [dataTokenId, setData] = useState({ ownerNFT: "0x0000000000000000000000000000000000000000", tokenId: "0", rarity: "0", ele: "0", level: "0", adult: "0", hashBase: "0", hashPower: "0", price: "0", active: "false" });
    const { tokenId } = useParams();
    const { web3Api, setWeb3Api } = useContext(Web3Context);
    const [account, setAccount] = useState("0x");
    useEffect(() => {
        const getAccount = async () => {
            const accounts = await web3Api.web3.eth.getAccounts();
            setAccount(accounts[0]);
        };
        web3Api.web3 && getAccount();
    }, [web3Api.web3, shouldReload]);
    // useEffect(() => {
    //     const loadProvider = async () => {
    //         const provider = await detectEthereumProvider();

    //         if (provider) {
    //             provider.request({ method: "eth_requestAccounts" });
    //             setWeb3Api({
    //                 web3: new Web3(provider),
    //                 provider,
    //             });
    //         } else {
    //             console.error("Please, Install Metamask");
    //             // alert("MetaMask not detected. Please install MetaMask.");
    //         }
    //     };
    //     loadProvider();
    // }, [shouldReload]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // console.log("Loadding");
                const response = await axios.get(`${API_URL}/${tokenId}`);
                if (response.data.length) {
                    setData(response.data[0]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [shouldReload]);
    const rarityNFT = ["", "Common", "Rare", "Legend", "Ultimate"];
    const eleNFT = ["", "Minnie", "Jackie", "Zeke", "Scolt", "Hugo", "Zeus"];
    // const dataTokenId = NFT__DATA.find((item) => item.tokenId == tokenId);
    // console.log(dataTokenId);
    const [isLeft, setList] = useState(false);
    const toggleList = () => {
        setList(!isLeft);
    };
    const [floatNumber, setfloatNumber] = useState();
    const handlefloatNumberChange = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value, 10));
        if (/^-?\d{0,6}\.?\d{0,2}$/.test(newValue) && !isNaN(newValue)) {
            setfloatNumber(newValue);
        }
    };
    const updateDetail = (field, newData) => {
        setData((prevDetail) => ({
            ...prevDetail,
            [field]: newData,
        }));
    };

    const handleLeft = async () => {
        setList(false);
        const contractNFT = new web3Api.web3.eth.Contract(abiERC721, addressNFT);
        switch (dataTokenId.active) {
            case "false":
                try {
                    const transactionList = {
                        from: account,
                        to: addressNFT,
                        data: contractNFT.methods.list(dataTokenId.tokenId, (floatNumber * 1000).toFixed() + "000000000000000").encodeABI(),
                    };
                    const transactionApproveForAll = {
                        from: account,
                        to: addressNFT,
                        data: contractNFT.methods.setApprovalForAll(addressNFT, "true").encodeABI(),
                    };
                    const transactionApprove = {
                        from: account,
                        to: addressNFT,
                        data: contractNFT.methods.approve(addressNFT, dataTokenId.tokenId).encodeABI(),
                    };
                    setLoading(true);
                    const isApproveForAll = await contractNFT.methods.isApprovedForAll(dataTokenId.ownerNFT, addressNFT).call();
                    if (!isApproveForAll) {
                        await web3Api.web3.eth.sendTransaction(transactionApproveForAll);
                        // setLoading(false);
                        // return;
                    }
                    const approveAddress = await contractNFT.methods.getApproved(dataTokenId.tokenId).call();
                    if (approveAddress != addressNFT) {
                        await web3Api.web3.eth.sendTransaction(transactionApprove);
                        // setLoading(false);
                        // return;
                    }
                    await web3Api.web3.eth.sendTransaction(transactionList);
                    toast.success("Listed successfully");
                    updateDetail("active", true);
                    setLoading(false);
                } catch (error) {
                    toast.error("Listed failed");
                    setLoading(false);
                }
                break;
            case "true":
                try {
                    const transactionChange = {
                        from: account,
                        to: addressNFT,
                        data: contractNFT.methods.change(dataTokenId.tokenId, (floatNumber * 1000).toFixed() + "000000000000000").encodeABI(),
                    };
                    setLoading(true);
                    await web3Api.web3.eth.sendTransaction(transactionChange);
                    toast.success("Changed successfully");
                    setLoading(false);
                } catch (error) {
                    toast.error("Changed failed");
                    setLoading(false);
                }
                break;
            default:
                break;
        }
    };
    const handleRight = async () => {
        setList(false);
        const contractNFT = new web3Api.web3.eth.Contract(abiERC721, addressNFT);
        if (dataTokenId.active == "true") {
            try {
                const transactionCancel = {
                    from: account,
                    to: addressNFT,
                    data: contractNFT.methods.cancel(dataTokenId.tokenId).encodeABI(),
                };
                setLoading(true);
                await web3Api.web3.eth.sendTransaction(transactionCancel);
                toast.success("Canceled successfully");
                updateDetail("active", false);
                setLoading(false);
            } catch (error) {
                toast.error("Canceled failed");
                setLoading(false);
            }
        } else if (dataTokenId.active == "false") {
            if (dataTokenId.level == "20") {
            } else if (dataTokenId.level == "10") {
                // upgrade
                try {
                    const contractKPET = new web3Api.web3.eth.Contract(abiERC20, addressKPET);
                    const balanceToken20 = await contractKPET.methods.balanceOf(account).call();
                    if (balanceToken20 < 10 * 10 ** 18) {
                        toast.error("Insufficient balance");
                        setLoading(false);
                        return;
                    }
                    const isApprove = await contractKPET.methods.allowance(account, addressNFT).call();
                    if (isApprove < 5 * 10 ** 18) {
                        const transactionApprove = {
                            from: account,
                            to: addressKPET,
                            data: contractKPET.methods.approve(addressNFT, balanceToken20).encodeABI(),
                        };
                        await web3Api.web3.eth.sendTransaction(transactionApprove);
                    }
                    const transactionUpgrade = {
                        from: account,
                        to: addressNFT,
                        data: contractNFT.methods.upgrade(dataTokenId.tokenId).encodeABI(),
                    };
                    setLoading(true);
                    await web3Api.web3.eth.sendTransaction(transactionUpgrade);
                    toast.success("Upgraded successfully");
                    updateDetail("level", (Number(dataTokenId.level) + 1).toFixed());
                    updateDetail("adult", "2");
                    setLoading(false);
                } catch (error) {
                    toast.error("Upgraded failed");
                    setLoading(false);
                }
            } else if (Number(dataTokenId.level) < 20) {
                // level up
                try {
                    const contractGEM = new web3Api.web3.eth.Contract(abiERC1155, addressGEM);
                    const transactionLevelUp = {
                        from: account,
                        to: addressNFT,
                        data: contractNFT.methods.levelUp(dataTokenId.tokenId).encodeABI(),
                    };
                    const transactionSetApproveAll = {
                        from: account,
                        to: addressGEM,
                        data: contractGEM.methods.setApprovalForAll(addressNFT, "true").encodeABI(),
                    };
                    setLoading(true);
                    const isApprovedForAll = await contractGEM.methods.isApprovedForAll(account, addressNFT).call();
                    if (!isApprovedForAll) {
                        await web3Api.web3.eth.sendTransaction(transactionSetApproveAll);
                    }
                    const balanceToken1155 = await contractGEM.methods.balanceOf(account, 1).call();
                    if (balanceToken1155 < dataTokenId.level * 500 + 1000) {
                        toast.error("Insufficient balance GEM");
                        setLoading(false);
                        return;
                    }
                    await web3Api.web3.eth.sendTransaction(transactionLevelUp);
                    toast.success("Level up successfully");
                    updateDetail("level", (Number(dataTokenId.level) + 1).toFixed());
                    setLoading(false);
                } catch (error) {
                    toast.error("Level up failed");
                    setLoading(false);
                }
            }
        }
    };
    if (dataTokenId.level == "0") {
        return <LoadingSpinner />;
    }
    if (dataTokenId.ownerNFT != account) {
        return <NotFound />;
    }
    return (
        <>
            {/* <CommonSection title="NFT detail" /> */}
            <section>
                <Container>
                    <Row>
                        <Col lg="6" md="6" sm="6" style={{ marginTop: "1.5rem" }}>
                            <img src={`${process.env.PUBLIC_URL}/img/pet${dataTokenId.ele}${dataTokenId.adult}.png`} alt="" className="w-100 single__nft-img" style={gradientStyle[Number(dataTokenId.rarity) - 1]} />
                        </Col>
                        <Col lg="6" md="6" sm="6">
                            <div className="single__nft__content ">
                                {/* <h2>{dataTokenId.title}</h2> */}
                                <div className="nft__creator d-flex gap-3 align-items-center" style={{ marginTop: "1.5rem" }}>
                                    <div className="creator__detail">
                                        <p>Owner</p>
                                        <h6>{dataTokenId.ownerNFT.slice(0, 6) + "..." + dataTokenId.ownerNFT.slice(38, 42)}</h6>
                                        <p>Token ID</p>
                                        <h6>{dataTokenId.tokenId}</h6>
                                        <p>Rarity</p>
                                        <h6>{rarityNFT[dataTokenId.rarity]}</h6>
                                        <p>Name</p>
                                        <h6>{eleNFT[dataTokenId.ele]}</h6>
                                        <p>Level</p>
                                        <h6>{dataTokenId.level}</h6>
                                        <p>Stage</p>
                                        <h6>{dataTokenId.adult == "1" ? "Child" : "Adult"}</h6>
                                        <p>Hash Base</p>
                                        <h6>{dataTokenId.hashBase}</h6>
                                        <p>Hash Power</p>
                                        <h6>{dataTokenId.hashPower}</h6>
                                    </div>
                                </div>

                                <div className=" mt-3 d-flex align-items-center justify-content-between">
                                    <button onClick={toggleList} className="singleNft-btn1 d-flex align-items-center gap-2 w-100" style={{ marginRight: "0.5rem" }}>
                                        <i class={statusAuction[dataTokenId.active][1]}></i>
                                        <span>{statusAuction[dataTokenId.active][0]}</span>
                                    </button>
                                    <button onClick={handleRight} className="singleNft-btn2 d-flex align-items-center gap-2 w-100">
                                        <i class={statusAuction2[dataTokenId.active][1]}></i>
                                        <span>{dataTokenId.active === "true" ? "Cancel" : dataTokenId.level === "10" ? "Upgrade" : dataTokenId.level === "20" ? "Max level" : statusAuction2[dataTokenId.active][0]}</span>
                                    </button>
                                    {isLeft && (
                                        <div class="yf-dialog">
                                            <div onClick={toggleList} class="exit-button">
                                                <i class="ri-close-fill"></i>
                                            </div>
                                            <div class="aveage-box mgt-20">
                                                <div class="tal">
                                                    <span class="price-label">
                                                        <input
                                                            class="vertical-children mgt-10 bold"
                                                            type="number"
                                                            // step="1"
                                                            value={floatNumber}
                                                            onChange={handlefloatNumberChange}
                                                        />
                                                    </span>
                                                    <button onClick={handleLeft} className="singleNft-btn1 d-flex align-items-center gap-2 w-100" style={{ marginTop: "0.5rem" }}>
                                                        <i class={statusAuction[dataTokenId.active][1]}></i>
                                                        <span>{statusAuction[dataTokenId.active][0]}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {loading && <LoadingSpinner />}
        </>
    );
};

export default MyNftDetails;
