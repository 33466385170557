import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./nft-card.css";

const NftCardMarket = (props) => {
    const [showBuy, setBuy] = useState(false);
    const { tokenId, rarity, ele, level, adult, hashBase, hashPower, price } = props.item;
    useEffect(() => {}, [showBuy]);
    const gradientStyle = [];
    const background = [
        "rgba(149, 190, 157, 0.3) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%",
        "rgba(95, 108, 18, 0.5) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%",
        "rgba(198, 3, 252, 0.35) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%",
        "rgba(162, 25, 24, 0.6) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%",
    ];
    for (let i = 0; i < background.length; i++) {
        gradientStyle.push({
            background: "linear-gradient(" + background[i] + ")",
            width: "100%",
            height: "auto",
        });
    }
    return (
        <div className="single__nft__card" style={gradientStyle[Number(rarity) - 1]}>
            <Link to={`/market/${tokenId}`} style={{ textDecoration: "none" }}>
                <div className="nft__img">
                    <img src={"./img/pet" + ele + adult + ".png"} alt="" className="w-100" />
                </div>
            </Link>

            <div className="nft__content">
                <div className="creator__info-wrapper d-flex gap-3">
                    <div className="creator__info w-100 d-flex align-items-center justify-content-between">
                        {/* <div>
                                <h6>Seller</h6>
                                <p>{ownerNFT.slice(0, 6) + "..." + ownerNFT.slice(38, 42)}</p>
                            </div> */}
                        <div>
                            <h6>Token ID</h6>
                            <p>{tokenId}</p>
                        </div>
                        <div>
                            <h6>Level</h6>
                            <p>{level}</p>
                        </div>
                        <div>
                            <h6>Hash Base</h6>
                            <p>{hashBase}</p>
                        </div>
                        <div>
                            <h6>Hash Power</h6>
                            <p>{hashPower}</p>
                        </div>
                    </div>
                </div>

                <div className=" mt-3 d-flex align-items-center justify-content-between">
                    <Link to={`/market/${tokenId}`} style={{ textDecoration: "none" }}>
                        <button className="bid__btn d-flex align-items-center gap-1" onClick={() => setBuy(true)}>
                            <i class="ri-shopping-bag-line"></i>
                            {(price / 10 ** 9).toFixed(2)} KPET
                        </button>
                    </Link>

                    <span hre className="history__link">
                        <Link to={`/market/${tokenId}`}>View Deitail</Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default NftCardMarket;
