import React, { useState, useRef, useEffect, useContext } from "react";
import "./header.css";
import { Container } from "reactstrap";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { NavLink, Link } from "react-router-dom";
import {
    abiERC20,
    abiERC721,
    abiERC1155,
    addressKPET,
    addressNFT,
    addressGEM,
} from "../../contract/data";
import toast from "react-hot-toast";
import Web3Context from "../Context/Web3Context";

const NAV__LINKS = [
    {
        display: "Home",
        url: "/home",
    },
    {
        display: "Marketplace",
        url: "/market",
    },
    {
        display: "Inventory",
        url: "/inventory",
    },
];
const Header = () => {
    const headerRef = useRef(null);
    const menuRef = useRef(null);
    const [shouldReload, reload] = useState(false);
    const reloadEffect = () => reload(!shouldReload);
    const [shouldReloadBalance, reloadBalance] = useState(false);
    const reloadEffectBalance = () => reloadBalance(!shouldReloadBalance);
    const getAccount = async () => {
        const accounts = await web3Api.web3.eth.getAccounts();
        setAccount(accounts[0]);
    };
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (
                document.body.scrollTop > 80 ||
                document.documentElement.scrollTop > 80
            ) {
                headerRef.current.classList.add("header__shrink");
            } else {
                headerRef.current.classList.remove("header__shrink");
            }
        });

        return () => {
            window.removeEventListener("scroll");
        };
    }, []);

    const toggleMenu = () => menuRef.current.classList.toggle("active__menu");

    // const [web3Api, setWeb3Api] = useState({
    //     provider: null,
    //     web3: null,
    // });
    const { web3Api, setWeb3Api } = useContext(Web3Context);

    const [isDivVisible, setDivVisible] = useState(false);
    const toggleDiv = () => {
        if (web3Api.provider == null) {
            toast.error("MetaMask not detected. Please install MetaMask.");
        }
        if (!Number(account)) {
            reloadEffect();
        } else {
            setDivVisible(!isDivVisible);
        }
        if (!balanceBNB) {
            reloadBalance();
            reloadEffectBalance();
        }
    };

    useEffect(async () => {
        const loadProvider = async () => {
            const provider = await detectEthereumProvider();
            if (provider) {
                await provider.request({ method: "eth_requestAccounts" });
                setWeb3Api({
                    web3: new Web3(provider),
                    provider,
                });
                web3Api.web3 && getAccount();
            }
        };
        loadProvider();
    }, [shouldReload]);
    const [account, setAccount] = useState("");
    const [balanceBNB, setBalanceBNB] = useState(0);
    const [balanceKPET, setBalanceKPET] = useState(0);
    const [balanceGEM, setBalanceGEM] = useState(0);
    const [balanceNFT, setBalanceNFT] = useState(0);
    useEffect(() => {
        if (account != "" && account != undefined) {
            try {
                const getBalance = async () => {
                    console.log(account);
                    const balance = await web3Api.web3.eth.getBalance(account);
                    const contractKPET = new web3Api.web3.eth.Contract(
                        abiERC20,
                        addressKPET
                    );
                    const contractNFT = new web3Api.web3.eth.Contract(
                        abiERC721,
                        addressNFT
                    );
                    const contractGEM = new web3Api.web3.eth.Contract(
                        abiERC1155,
                        addressGEM
                    );
                    const balance2 = await contractKPET.methods
                        .balanceOf(account)
                        .call();
                    const balance3 = await contractNFT.methods
                        .balanceOf(account)
                        .call();
                    const balance4 = await contractGEM.methods
                        .balanceOf(account, 1)
                        .call();
                    setBalanceBNB((balance / 10 ** 18).toFixed(3));
                    setBalanceKPET((balance2 / 10 ** 18).toFixed(2));
                    setBalanceNFT(balance3);
                    setBalanceGEM(balance4);
                };
                web3Api.web3 && getBalance();
            } catch (error) {}
        }
    }, [web3Api.web3, shouldReloadBalance]);
    useEffect(() => {
        web3Api.web3 && getAccount();
    }, [web3Api.web3, shouldReload]);
    const [currentNetwork, setCurrentNetwork] = useState(null);
    const desiredNetwork = 97; // Change this to the desired network ID (e.g., 1 for Ethereum Mainnet)
    useEffect(() => {
        async function setupWeb3() {
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum);
                try {
                    // Request permission to access the user's MetaMask account
                    await window.ethereum.request({
                        method: "eth_requestAccounts",
                    });
                    const provider = await detectEthereumProvider();
                    setWeb3Api({
                        web3: new Web3(provider),
                        provider,
                    });
                    web3Api.web3 && getAccount();

                    // Get the current network ID from MetaMask
                    const networkId = await web3.eth.net.getId();
                    setCurrentNetwork(networkId);

                    // Check if the current network matches the desired network
                    if (networkId !== desiredNetwork) {
                        // Switch to the desired network
                        await window.ethereum.request({
                            method: "wallet_switchEthereumChain",
                            params: [
                                { chainId: `0x${desiredNetwork.toString(16)}` },
                            ],
                        });
                    }
                } catch (error) {
                    // console.error(error);
                }
            } else {
                // console.error("MetaMask not detected. Please install MetaMask.");
            }
        }
        setupWeb3();
    }, [desiredNetwork]);
    return (
        <header className="header" ref={headerRef}>
            <Container>
                <div className="navigation">
                    <Link to="/home" style={{ textDecoration: "none" }}>
                        <div className="logo">
                            <h2 className=" d-flex gap-2 align-items-center ">
                                Open NFT
                            </h2>
                        </div>
                    </Link>

                    <div
                        className="nav__menu"
                        ref={menuRef}
                        onClick={toggleMenu}
                    >
                        <ul className="nav__list">
                            {NAV__LINKS.map((item, index) => (
                                <li className="nav__item" key={index}>
                                    <NavLink
                                        to={item.url}
                                        className={(navClass) =>
                                            navClass.isActive ? "active" : ""
                                        }
                                    >
                                        {item.display}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="nav__right d-flex align-items-center gap-5 ">
                        {isDivVisible && (
                            <div class="yf-dialog">
                                <div onClick={toggleDiv} class="exit-button">
                                    <i class="ri-close-fill"></i>
                                </div>
                                <div class="aveage-box mgt-20">
                                    <div class="tal">
                                        <div class="token-row">
                                            <div class="token-box">
                                                <span class="token-label">
                                                    KPET:
                                                </span>
                                                <span class="token-value">
                                                    {balanceKPET}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="token-row">
                                            <div class="token-box">
                                                <span class="token-label">
                                                    NFTs:
                                                </span>
                                                <span class="token-value">
                                                    {balanceNFT}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="token-row">
                                            <div class="token-box">
                                                <span class="token-label">
                                                    GEM:
                                                </span>
                                                <span class="token-value">
                                                    {balanceGEM}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="token-row">
                                            <div class="token-box">
                                                <span class="token-label">
                                                    BNB:
                                                </span>
                                                <span class="token-value">
                                                    {balanceBNB}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <button
                            onClick={toggleDiv}
                            className="btn d-flex gap-2 align-items-center"
                        >
                            <span>
                                <i class="">
                                    {account
                                        ? account.slice(0, 6) +
                                          "..." +
                                          account.slice(38, 42)
                                        : "Connect Wallet"}
                                </i>
                            </span>
                        </button>

                        <span className="mobile__menu">
                            <i class="ri-menu-line" onClick={toggleMenu}></i>
                        </span>
                    </div>
                </div>
            </Container>
        </header>
    );
};

export default Header;
