import React, { useState } from "react";
import { Link } from "react-router-dom";
// import axios from "axios";
import "./nft-card-inventory.css";
import { abiERC20, abiERC721, abiERC1155, addressKPET, addressNFT, addressGEM } from "../../../contract/data";

const NftCardInventory = (props) => {
    const [showModal, setShowModal] = useState(false);
    const { ownerNFT, tokenId, rarity, ele, level, adult, hashBase, hashPower, price, active } = props.item;
    const statusAuction = { true: ["Ajust", "ri-edit-line"], false: ["List", "ri-price-tag-3-line"] };
    const statusAuction2 = { true: ["Cancel", "ri-close-line"], false: ["Level Up", "ri-arrow-up-line"] };
    const gradientStyle = [];
    const background = [
        "rgba(149, 190, 157, 0.3) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%",
        "rgba(95, 108, 18, 0.5) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%",
        "rgba(198, 3, 252, 0.35) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%",
        "rgba(162, 25, 24, 0.6) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%",
    ];
    for (let i = 0; i < background.length; i++) {
        gradientStyle.push({
            background: "linear-gradient(" + background[i] + ")",
            width: "100%",
            height: "auto",
        });
    }
    const listNFT = async () => {
        try {
            // console.log("PUT");
            // await axios.put("http://localhost:3001/v1/" + tokenId, { price: "12000000000", active: "true" });
        } catch (error) {
            console.log("err");
        }
    };
    return (
        <div className="single__nft__card" style={gradientStyle[Number(rarity) - 1]}>
            <Link to={`/inventory/${tokenId}`} style={{ textDecoration: "none" }}>
                <div className="nft__img">
                    <img src={"./img/pet" + ele + adult + ".png"} alt="" className="w-100" />
                </div>
            </Link>
            <div className="creator__info w-100 d-flex align-items-center justify-content-between">
                <div>
                    <h6>Token ID</h6>
                    <p>{tokenId}</p>
                </div>
                <div>
                    <h6>Level</h6>
                    <p>{level}</p>
                </div>
                <div>
                    <h6>Hash Base</h6>
                    <p>{hashBase}</p>
                </div>
                <div>
                    <h6>Hash Power</h6>
                    <p>{hashPower}</p>
                </div>
            </div>
            <div className="nft__content">
                <div className="mt-3 d-flex align-items-center justify-content-between">
                    <Link to={`/inventory/${tokenId}`} style={{ textDecoration: "none" }}>
                        <button onClick={listNFT} className="bid__btn d-flex align-items-center gap-1">
                            <i class={statusAuction[active][1]}></i> {statusAuction[active][0]}
                        </button>
                    </Link>
                    <Link to={`/inventory/${tokenId}`} style={{ textDecoration: "none" }}>
                        <button className="bid__btn d-flex align-items-center gap-1" onClick={() => setShowModal(true)}>
                            <i class={statusAuction2[active][1]}></i> {active === "true" ? "Cancel" : level === "10" ? "Upgrade" : level === "20" ? "Max level" : statusAuction2[active][0]}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NftCardInventory;
