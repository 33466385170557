import React, { createContext, useState } from "react";

const Web3Context = createContext();

export const Web3Provider = ({ children }) => {
    const [web3Api, setWeb3Api] = useState({
        provider: null,
        web3: null,
    });

    return <Web3Context.Provider value={{ web3Api, setWeb3Api }}>{children}</Web3Context.Provider>;
};

export default Web3Context;
