import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import NftCardMarket from "../Nft-card/NftCardMarket";
// import { data } from "../../../assets/data/data.js";

import "./live-auction.css";
import { API_URL } from "../../../configs/constant";

const LiveAuction = () => {
    const [data, setData] = useState([]);
    const [shouldReload, reload] = useState(false);
    const reloadEffect = () => reload(!shouldReload);
    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Loadding");
                const response = await axios.get(API_URL);
                setData(
                    response.data
                        .filter((item) => item.active === "true" && Number(item.level) <= 21)
                        .sort((a, b) => {
                            return Number(a.timeUp) - Number(b.timeUp);
                        })
                );
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [shouldReload]);
    return (
        <section>
            <Container>
                <Row>
                    <Col lg="12" className="mb-5">
                        <div className="live__auction__top d-flex align-items-center justify-content-between ">
                            <h3>Recently Listed</h3>
                            <span>
                                <Link to="/market">Go to marketplace</Link>
                            </span>
                        </div>
                    </Col>

                    {data
                        ?.slice()
                        .reverse()
                        .slice(0, 4)
                        .map((item) => (
                            <Col lg="3" md="4" sm="6" className="mb-4">
                                <NftCardMarket key={item.id} item={item} />
                            </Col>
                        ))}
                </Row>
            </Container>
        </section>
    );
};

export default LiveAuction;
