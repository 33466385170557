import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import "../styles/inventory.css";
import NftCardInventory from "../components/ui/Nft-card/NftCardInventory";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { API_URL } from "../configs/constant";

const Inventory = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const [web3Api, setWeb3Api] = useState({
        provider: null,
        web3: null,
    });
    useEffect(() => {
        const loadProvider = async () => {
            const provider = await detectEthereumProvider();

            if (provider) {
                provider.request({ method: "eth_requestAccounts" });
                setWeb3Api({
                    web3: new Web3(provider),
                    provider,
                });
            } else {
                console.error("Please, Install Metamask");
                // alert("Please, Install Metamask To Connect Wallet");
            }
        };
        loadProvider();
    }, []);
    const [account, setAccount] = useState(0);
    useEffect(() => {
        const getAccount = async () => {
            const accounts = await web3Api.web3.eth.getAccounts();
            setAccount(accounts[0]);
            // console.log(account);
        };
        web3Api.web3 && getAccount();
    }, [web3Api.web3]);
    const [shouldReload, reload] = useState(false);
    const reloadEffect = () => reload(!shouldReload);
    const [data, setData] = useState([]);
    const [dataCache, setDataCache] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (account) {
                    // console.log("Loadding");
                    // console.log(account);
                    const response = await axios.get(
                        `${API_URL}/inventory/${account}`
                    );
                    setData(
                        response.data.sort((a, b) => {
                            return Number(a.tokenId) - Number(b.tokenId);
                        })
                    );
                    setDataCache(
                        response.data.sort((a, b) => {
                            return Number(a.tokenId) - Number(b.tokenId);
                        })
                    );
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [account, shouldReload]);
    const handleCategory = (e) => {};
    const handleItems = (e) => {
        const filterValue = e.target.value;
        if (filterValue === "allItems") {
            // reloadEffect();
            setData(dataCache);
        }

        if (filterValue === "child") {
            const filterData = dataCache.filter((item) => item.adult === "1");

            setData(filterData);
        }

        if (filterValue === "adult") {
            const filterData = dataCache.filter((item) => item.adult === "2");

            setData(filterData);
        }

        if (filterValue === "cat") {
            const filterData = dataCache.filter((item) => item.ele === "1");

            setData(filterData);
        }

        if (filterValue === "dog") {
            const filterData = dataCache.filter((item) => item.ele === "2");

            setData(filterData);
        }

        if (filterValue === "bull") {
            const filterData = dataCache.filter((item) => item.ele === "3");

            setData(filterData);
        }

        if (filterValue === "tyger") {
            const filterData = dataCache.filter((item) => item.ele === "4");

            setData(filterData);
        }

        if (filterValue === "lion") {
            const filterData = dataCache.filter((item) => item.ele === "5");

            setData(filterData);
        }

        if (filterValue === "dragon") {
            const filterData = dataCache.filter((item) => item.ele === "6");

            setData(filterData);
        }

        if (filterValue === "common") {
            const filterData = dataCache.filter((item) => item.rarity === "1");

            setData(filterData);
        }

        if (filterValue === "rare") {
            const filterData = dataCache.filter((item) => item.rarity === "2");

            setData(filterData);
        }

        if (filterValue === "legend") {
            const filterData = dataCache.filter((item) => item.rarity === "3");

            setData(filterData);
        }

        if (filterValue === "ultimate") {
            const filterData = dataCache.filter((item) => item.rarity === "4");

            setData(filterData);
        }
    };

    const handleSort = (e) => {
        const filterValueSort = e.target.value;
        if (filterValueSort === "true") {
            const filterData = dataCache.filter(
                (item) => item.active === "true"
            );

            setData(filterData);
        }

        if (filterValueSort === "false") {
            const filterData = dataCache.filter(
                (item) => item.active === "false"
            );

            setData(filterData);
        }
    };

    return (
        <>
            <section>
                <Container>
                    <Row>
                        <Col lg="12" className="mb-5">
                            {/* <div>
                                <br />
                                <br />
                                <br />
                                <br />
                            </div> */}
                            <div className="market__product__filter d-flex align-items-center justify-content-between">
                                <div className="filter__left d-flex align-items-center gap-5">
                                    <div className="all__category__filter">
                                        <select onChange={handleCategory}>
                                            <option>All Categories</option>
                                            <option value="lkps">
                                                Open NFT
                                            </option>
                                        </select>
                                    </div>

                                    <div className="all__items__filter">
                                        <select onChange={handleItems}>
                                            <option value="allItems">
                                                All Rarity
                                            </option>
                                            <option value="common">
                                                Common
                                            </option>
                                            <option value="rare">Rare</option>
                                            <option value="legend">
                                                Legend
                                            </option>
                                            <option value="ultimate">
                                                Ultimate
                                            </option>
                                        </select>
                                    </div>

                                    <div className="all__items__filter">
                                        <select onChange={handleItems}>
                                            <option value="allItems">
                                                All Kind
                                            </option>
                                            <option value="cat">Cat</option>
                                            <option value="dog">Dog</option>
                                            <option value="bull">Bull</option>
                                            <option value="tyger">Tyger</option>
                                            <option value="lion">Lion</option>
                                            <option value="dragon">
                                                Dragon
                                            </option>
                                        </select>
                                    </div>
                                    <div className="all__items__filter">
                                        <select onChange={handleItems}>
                                            <option value="allItems">
                                                All Type
                                            </option>
                                            <option value="child">Child</option>
                                            <option value="adult">Adult</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="filter__right">
                                    <select onChange={handleSort}>
                                        <option>Sort By</option>
                                        <option value="true">Selling</option>
                                        <option value="false">
                                            Not Selling
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </Col>

                        {data
                            ?.slice()
                            .reverse()
                            .map((item) => (
                                <Col
                                    lg="3"
                                    md="4"
                                    sm="6"
                                    className="mb-4"
                                    key={item.tokenId}
                                >
                                    <NftCardInventory item={item} />
                                </Col>
                            ))}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Inventory;
