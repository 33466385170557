export const imgNFT = [
    ["https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet11.png", "https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet12.png"],
    ["https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet21.png", "https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet22.png"],
    ["https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet31.png", "https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet32.png"],
    ["https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet41.png", "https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet42.png"],
    ["https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet51.png", "https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet52.png"],
    ["https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet61.png", "https://violet-ready-peafowl-740.mypinata.cloud/ipfs/QmXi48nAFsWr3dUVbSVqvAR4HJRt8PrjyxjS5boWf8r3vN/pet62.png"],
];

export const gradientStyle = [
    {
        background: "linear-gradient(rgba(149, 190, 157, 0.3) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%)",
        width: "100%",
        height: "auto",
    },
    {
        background: "linear-gradient(rgba(95, 108, 18, 0.5) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%)",
        width: "100%",
        height: "auto",
    },
    {
        background: "linear-gradient(rgba(198, 3, 252, 0.35) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%)",
        width: "100%",
        height: "auto",
    },
    {
        background: "linear-gradient(rgba(162, 25, 24, 0.6) 0%, rgba(70, 57, 92, 0.3) 60%, rgba(42, 44, 56, 0.3) 75%, rgba(31, 37, 49, 0.3) 100%)",
        width: "100%",
        height: "auto",
    },
];

export const NFT__DATA = [
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "1",
        rarity: "1",
        ele: "1",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "2",
        rarity: "2",
        ele: "1",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "3",
        rarity: "1",
        ele: "2",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "4",
        rarity: "2",
        ele: "2",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "5",
        rarity: "1",
        ele: "3",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "6",
        rarity: "2",
        ele: "3",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "7",
        rarity: "1",
        ele: "4",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "8",
        rarity: "2",
        ele: "4",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "9",
        rarity: "4",
        ele: "5",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "10",
        rarity: "3",
        ele: "5",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "false",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "11",
        rarity: "2",
        ele: "6",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "12",
        rarity: "1",
        ele: "6",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "false",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "101",
        rarity: "1",
        ele: "1",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "102",
        rarity: "2",
        ele: "1",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "103",
        rarity: "1",
        ele: "2",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "104",
        rarity: "2",
        ele: "2",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "105",
        rarity: "1",
        ele: "3",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "106",
        rarity: "2",
        ele: "3",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "107",
        rarity: "1",
        ele: "4",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "108",
        rarity: "2",
        ele: "4",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "109",
        rarity: "4",
        ele: "5",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "110",
        rarity: "3",
        ele: "5",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "false",
        __v: 0,
    },
    {
        _id: "6512ef1db5f9572cb8b768f7",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "111",
        rarity: "2",
        ele: "6",
        level: "1",
        adult: "1",
        hashBase: "2",
        hashPower: "2",
        price: "1000000000",
        active: "true",
        __v: 0,
    },
    {
        _id: "6512ef2cb5f9572cb8b769a8",
        ownerNFT: "0x55555D4de8df0c455C2Ff368253388FE669a8888",
        tokenId: "112",
        rarity: "1",
        ele: "6",
        level: "2",
        adult: "2",
        hashBase: "3",
        hashPower: "3",
        price: "2000000000",
        active: "false",
        __v: 0,
    },
    
];
