import "../styles/NotFound.css";
const notFound = () => {
    return (
        <>
            <section>
                <div className="not-found-container">
                    <h1 className="not-found-title">404 - NFT Not Found</h1>
                    <p className="not-found-message">Sorry, the page you are looking for might not exist.</p>
                </div>
            </section>
        </>
    );
};

export default notFound;
