import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import "./footer.css";

import { Link } from "react-router-dom";

const MY__ACCOUNT = [
    {
        display: "Author Profile",
        url: "/inventory",
    },
    {
        display: "Create Item",
        url: "/inventory",
    },
    {
        display: "Collection",
        url: "/inventory",
    },
    {
        display: "Edit Profile",
        url: "/inventory",
    },
];

const RESOURCES = [
    {
        display: "Help Center",
        url: "#",
    },
    {
        display: "Partner",
        url: "#",
    },
    {
        display: "Community",
        url: "#",
    },
    {
        display: "Activity",
        url: "#",
    },
];

// const COMPANY = [
//   {
//     display: "About",
//     url: "#",
//   },
//   {
//     display: "Career",
//     url: "#",
//   },
//   {
//     display: "Ranking",
//     url: "#",
//   },
//   {
//     display: "Contact Us",
//     url: "/contact",
//   },
// ];

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col lg="4" md="6" sm="6" className="mb-4">
                        <div className="logo">
                            <h2 className=" d-flex gap-2 align-items-center ">
                                {/* <span>
                  <i class="ri-water-flash-line"></i>
                </span> */}
                                Open NFT
                            </h2>
                            <p>
                                Explore the world of NFTs Discover new pet with
                                high hash power, featured items, and more.
                            </p>
                        </div>
                    </Col>

                    <Col lg="2" md="3" sm="6" className="mb-4">
                        <h5>My Account</h5>
                        <ListGroup className="list__group">
                            {MY__ACCOUNT.map((item, index) => (
                                <ListGroupItem
                                    key={index}
                                    className="list__item"
                                >
                                    <Link to={item.url}> {item.display} </Link>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </Col>

                    <Col lg="2" md="3" sm="6" className="mb-4">
                        <h5>Resources</h5>
                        <ListGroup className="list__group">
                            {RESOURCES.map((item, index) => (
                                <ListGroupItem
                                    key={index}
                                    className="list__item"
                                >
                                    <Link to={item.url}> {item.display} </Link>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </Col>

                    <Col lg="4" md="6" sm="6" className="mb-4">
                        <h5>Newsletter</h5>
                        <input
                            type="text"
                            className="newsletter"
                            placeholder="Email"
                        />
                        <div className="social__links d-flex gap-3 align-items-center ">
                            <span>
                                <a
                                    href="https://www.facebook.com/"
                                    target="_blank"
                                >
                                    <i className="ri-facebook-line"></i>
                                </a>
                            </span>
                            <span>
                                <a
                                    href="https://www.instagram.com/"
                                    target="_blank"
                                >
                                    <i className="ri-instagram-line"></i>
                                </a>
                            </span>
                            <span>
                                <a
                                    href="https://t.me/+CyVwXYDV4nsxMTVl"
                                    target="_blank"
                                >
                                    <i className="ri-telegram-line"></i>
                                </a>
                            </span>
                            <span>
                                <a
                                    href="https://discord.gg/edhERdAm"
                                    target="_blank"
                                >
                                    <i className="ri-discord-line"></i>
                                </a>
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
