import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../pages/Home";
import Market from "../pages/Market";
import Create from "../pages/Inventory";
import NftDetails from "../pages/NftDetails";
import MyNftDetails from "../pages/MyNftDetails";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/market" element={<Market />} />
      <Route path="/inventory" element={<Create />} />
      <Route path="/market/:tokenId" element={<NftDetails />} />
      <Route path="/inventory/:tokenId" element={<MyNftDetails />} />
    </Routes>
  );
};

export default Routers;
