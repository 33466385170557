import React, { useCallback, useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Web3 from "web3";
import LoadingSpinner from "../../Effect/LoadingSpinner.jsx";
import "./dashboard.css";
// import detectEthereumProvider from "@metamask/detect-provider";
import {
    abiERC20,
    abiERC721,
    abiERC1155,
    addressKPET,
    addressNFT,
    addressGEM,
} from "../../contract/data";
import toast from "react-hot-toast";
import Web3Context from "../Context/Web3Context.js";

const Dashboard = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const [loading, setLoading] = useState(false);
    const [integerNumber, setIntegerNumber] = useState(0);
    const handleIntegerNumberChange = (event) => {
        // console.log(integerNumber)
        const newValue = Math.abs(parseInt(event.target.value, 10));
        if (!isNaN(newValue)) {
            setIntegerNumber(newValue);
        }
    };
    const [shouldReload, reload] = useState(false);
    const reloadEffect = () => reload(!shouldReload);
    const [shouldReloadHash, reloadHash] = useState(false);
    const reloadHashEffect = () => reloadHash(!shouldReloadHash);
    const [shouldReloadBalance, reloadBalance] = useState(false);
    const reloadEffectBalance = () => reloadBalance(!shouldReloadBalance);
    const { web3Api, setWeb3Api } = useContext(Web3Context);

    const [isDivVisible, setDivVisible] = useState(false);
    const toggleMint = async () => {
        if (web3Api.web3 == null) {
            toast.error("Please install Metamask");
            return;
        }
        try {
            if (!Number(account)) {
                reloadEffect();
            } else {
                setDivVisible(!isDivVisible);
            }
            if (!balanceBNB) {
                const contractKPET = new web3Api.web3.eth.Contract(abiERC20, addressKPET);
                const balanceToken20 = await contractKPET.methods.balanceOf(account).call();
                if (balanceToken20 < 10 * 10 ** 18) {
                    alert("Insufficient balance");
                } else {
                    setLoading(true);
                    const contractNFT = new web3Api.web3.eth.Contract(abiERC721, addressNFT);
                    const transactionMint = {
                        from: account,
                        to: addressNFT,
                        data: contractNFT.methods.mint().encodeABI(),
                    };
                    const transactionApprove = {
                        from: account,
                        to: addressKPET,
                        data: contractKPET.methods.approve(addressNFT, balanceToken20).encodeABI(),
                    };
                    const isApprove = await contractKPET.methods
                        .allowance(account, addressNFT)
                        .call();
                    if (isApprove < 10 * 10 ** 18) {
                        await web3Api.web3.eth.sendTransaction(transactionApprove);
                    }
                    try {
                        await web3Api.web3.eth.sendTransaction(transactionMint);
                        toast.success("Mint successfully, please check in inventory");
                    } catch (error) {
                        toast.error("Mint failed");
                    }
                }
            }
            setLoading(false);
        } catch (error) {
            toast.error("Mint failed");
            setLoading(false);
        }
    };
    const toggleAirDrop = async () => {
        if (web3Api.web3 == null) {
            toast.error("Please install Metamask");
            return;
        }
        try {
            if (!Number(account)) {
                reloadEffect();
            } else {
                setDivVisible(!isDivVisible);
            }
            if (!balanceBNB) {
                const contractKPET = new web3Api.web3.eth.Contract(abiERC20, addressKPET);
                const transactionAirDrop = {
                    from: account,
                    to: addressKPET,
                    data: contractKPET.methods.airDrop(account).encodeABI(),
                };
                const isAirDrop = await contractKPET.methods.boolAirDrops(account).call();
                if (!isAirDrop) {
                    setLoading(true);
                    const txHashExchange = await web3Api.web3.eth.sendTransaction(
                        transactionAirDrop
                    );
                    toast.success("AirDrop successfully");
                } else {
                    // alert("Out of stock");
                    toast.error("You have already airdrop");
                }
            }
            setLoading(false);
        } catch (error) {
            toast.error("AirDrop failed");
            setLoading(false);
        }
    };
    const toggleExchange = async () => {
        if (web3Api.web3 == null) {
            toast.error("Please install Metamask");
            return;
        }
        try {
            setLoading(true);
            console.log(abiERC20, typeof abiERC20);
            if (!Number(account)) {
                reloadEffect();
            } else {
                setDivVisible(!isDivVisible);
            }
            if (!balanceBNB) {
                const contractNFT = new web3Api.web3.eth.Contract(abiERC721, addressNFT);
                const contractGEM = new web3Api.web3.eth.Contract(abiERC1155, addressGEM);
                const transactionExchange = {
                    from: account,
                    to: addressNFT,
                    data: contractNFT.methods.exchange(integerNumber).encodeABI(),
                };
                const transactionSetApproveAll = {
                    from: account,
                    to: addressGEM,
                    data: contractGEM.methods.setApprovalForAll(addressNFT, "true").encodeABI(),
                };
                const isApprovedForAll = await contractGEM.methods
                    .isApprovedForAll(account, addressNFT)
                    .call();
                if (!isApprovedForAll) {
                    await web3Api.web3.eth.sendTransaction(transactionSetApproveAll);
                }
                await web3Api.web3.eth.sendTransaction(transactionExchange);
                toast.success("Exchange successfully");
            }
            setLoading(false);
        } catch (error) {
            toast.error("Exchange failed");
            setLoading(false);
        }
    };

    const toggleClaim = async () => {
        if (web3Api.web3 == null) {
            toast.error("Please install Metamask");
            return;
        }
        try {
            setLoading(true);
            if (!Number(account)) {
                reloadEffect();
            } else {
                setDivVisible(!isDivVisible);
            }
            if (!balanceBNB) {
                const contractNFT = new web3Api.web3.eth.Contract(abiERC721, addressNFT);
                const transactionClaim = {
                    from: account,
                    to: addressNFT,
                    data: contractNFT.methods.claim().encodeABI(),
                };
                const resultClaim = await web3Api.web3.eth.sendTransaction(transactionClaim);
                toast.success("Claimed successfully");
            }
            setLoading(false);
        } catch (error) {
            toast.error("Claim failed");
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     const loadProvider = async () => {
    //         const provider = await detectEthereumProvider();

    //         if (provider) {
    //             provider.request({ method: "eth_requestAccounts" });
    //             setWeb3Api({
    //                 web3: new Web3(provider),
    //                 provider,
    //             });
    //         } else {
    //             console.error("Please, Install Metamask");
    //             // alert("MetaMask not detected. Please install MetaMask.");
    //         }
    //     };
    //     loadProvider();
    // }, [shouldReload]);
    const [account, setAccount] = useState("0x");
    const [balanceBNB, setBalanceBNB] = useState(0);
    const [balanceKPET, setBalanceKPET] = useState(0);
    const [balanceGEM, setBalanceGEM] = useState(0);
    // const [balanceNFT, setBalanceNFT] = useState(0);
    const [totalHash, setTotalHash] = useState(0);
    const [myHash, setMyHash] = useState(0);
    const [myReward, setMyReward] = useState(0);
    const updateHash = async () => {
        try {
            const contractNFT = new web3Api.web3.eth.Contract(abiERC721, addressNFT);
            const contractGEM = new web3Api.web3.eth.Contract(abiERC1155, addressGEM);
            const ttGem = await contractGEM.methods.balanceOf(account, 1).call();
            const tHash = await contractNFT.methods.totalHash().call();
            const mHash = await contractNFT.methods.detailUsers(account).call();
            // console.log(mHash);
            setTotalHash(tHash);
            setBalanceGEM(ttGem);
            setMyHash(mHash.hashPower);
            setMyReward(
                (
                    (Number(mHash.reward) +
                        mHash.hashPower * (Date.now() / 1000 + 5 - mHash.hisTimestamp)) /
                    10000
                ).toFixed()
            );
            setIntegerNumber(ttGem);
        } catch (error) {}
    };
    useEffect(() => {
        if (account != "0x" && account != undefined) {
            try {
                const getBalance = async () => {
                    console.log(account);
                    const contractKPET = new web3Api.web3.eth.Contract(abiERC20, addressKPET);
                    const balance2 = await contractKPET.methods.balanceOf(account).call();
                    // if (balance2 < 10 * 10 ** 18) {
                    //     alert("Min is 10 KPET");
                    // } else {
                    setBalanceKPET((balance2 / 10 ** 18).toFixed(2));
                    // }
                };
                web3Api.web3 && getBalance();
            } catch (error) {}
        }
    }, [web3Api.web3, shouldReloadBalance]);
    const getAccount = async () => {
        const accounts = await web3Api.web3.eth.getAccounts();
        setAccount(accounts[0]);
    };
    useEffect(() => {
        web3Api.web3 && getAccount();
    }, [web3Api.web3, shouldReload]);
    const [currentNetwork, setCurrentNetwork] = useState(null);
    const desiredNetwork = 97;
    useEffect(() => {
        async function setupWeb3() {
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum);
                try {
                    await window.ethereum.request({ method: "eth_requestAccounts" });
                    const networkId = await web3.eth.net.getId();
                    setCurrentNetwork(networkId);
                    if (networkId !== desiredNetwork) {
                        await window.ethereum.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: `0x${desiredNetwork.toString(16)}` }],
                        });
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                console.error("MetaMask not detected. Please install MetaMask.");
            }
        }
        setupWeb3();
    }, [desiredNetwork]);
    return (
        <section className="dashboard__section">
            <Container>
                <Row>
                    <Col lg="6" md="6">
                        <div className="dashboard__content">
                            <h2>
                                Explore the world of<span>NFTs</span>
                                <br />
                                Discover new pet with high hash power, featured items, and more.
                            </h2>
                            <p>
                                "Welcome to your decentralized NFT marketplace. Create, buy, and
                                sell NFTs!"
                            </p>
                            <div className="dashboard__btns d-flex align-items-center gap-4">
                                <button className=" home__btn d-flex align-items-center gap-2">
                                    <i class="ri-store-2-line"></i>{" "}
                                    <Link to="/market">Explore</Link>
                                </button>
                                <button className="home__btn d-flex align-items-center gap-2">
                                    <i class="ri-ball-pen-line"></i>
                                    <Link to="/inventory">Create</Link>
                                </button>
                                <button
                                    onClick={toggleMint}
                                    className="home__btn d-flex align-items-center gap-2"
                                >
                                    <i class="ri-hammer-line"></i>
                                    <span>Mint</span>
                                </button>
                                <button
                                    onClick={toggleAirDrop}
                                    className="home__btn d-flex align-items-center gap-2"
                                >
                                    <i class="ri-hand-coin-line"></i>
                                    <span>Free KPET</span>
                                </button>
                            </div>
                        </div>
                    </Col>

                    <Col lg="6" md="6">
                        <div className="dashboard__detail">
                            <div className="hash-detail">
                                <button onClick={updateHash} className="refresh-btn">
                                    <i class="ri-refresh-line"></i>
                                </button>
                                <p className="small opa-6">Total Hash Power</p>
                                <p className="mgt-10 bold">{totalHash}</p>
                                <p className="small opa-6">My Hash Power</p>
                                <div className="por dib mgt-10">
                                    <div className="bor bold">
                                        <span>{myHash}</span>
                                    </div>
                                </div>
                                <div class="dib speed-show">
                                    <p class="small opa-6 vertical-children">
                                        <span class="tac">1000 Hash Power ≈ 6 GEM/min</span>
                                    </p>
                                    <p class="vertical-children mgt-10 bold">
                                        <span class="mgl-5 ">{myReward}</span>
                                        <span class="mgl-5">GEM</span>
                                    </p>
                                    <button
                                        onClick={toggleClaim}
                                        id="take-btn"
                                        class="btn-primary por mgl-10"
                                    >
                                        Claim GEM
                                    </button>
                                    {/* <button id="take-btn" class="btn-primary por mgl-10">Claim GEM</button> */}
                                </div>
                                <div class="dib speed-show">
                                    <p class="small opa-6 vertical-children">
                                        <span class="tac">Exchange: 1000 GEM ~ 1 KPET</span>
                                    </p>
                                    <input
                                        class="vertical-children mgt-10 bold"
                                        type="number"
                                        // step="1"
                                        value={integerNumber}
                                        onChange={handleIntegerNumberChange}
                                    />
                                    <button
                                        onClick={toggleExchange}
                                        id="exchange-btn"
                                        class="btn-primary por mgl-10"
                                        style={{ marginTop: "1rem" }}
                                    >
                                        Exchange GEM
                                    </button>
                                    {loading && <LoadingSpinner />}
                                    {/* <button id="take-btn" class="btn-primary por mgl-10">Claim GEM</button> */}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Dashboard;
