import React, { useState, useEffect, useContext } from "react";
import LoadingSpinner from "../Effect/LoadingSpinner.jsx";
import NotFound from "./NotFound";
// import Web3 from "web3";
// import detectEthereumProvider from "@metamask/detect-provider";
import axios from "axios";
import {
    abiERC20,
    abiERC721,
    abiERC1155,
    addressKPET,
    addressNFT,
    addressGEM,
} from "../contract/data";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { gradientStyle } from "../assets/data/data";
import "../styles/nft-details.css";
import toast from "react-hot-toast";
import Web3Context from "../components/Context/Web3Context.js";
import { API_URL } from "../configs/constant.js";

const NftDetails = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const [shouldReload, reload] = useState(false);
    const reloadEffect = () => reload(!shouldReload);
    const [loading, setLoading] = useState(false);
    const [dataTokenId, setData] = useState({
        ownerNFT: "0x0000000000000000000000000000000000000000",
        tokenId: "0",
        rarity: "0",
        ele: "0",
        level: "0",
        adult: "0",
        hashBase: "0",
        hashPower: "0",
        price: "0",
        active: false,
    });
    const { tokenId } = useParams();
    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Loadding");
                setLoading(true);
                const response = await axios.get(`${API_URL}/${tokenId}`);
                setLoading(false);
                if (response.data.length) {
                    setData(response.data[0]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [shouldReload]);
    const rarityNFT = ["", "Common", "Rare", "Legend", "Ultimate"];
    const eleNFT = ["", "Minnie", "Jackie", "Zeke", "Scolt", "Hugo", "Zeus"];
    const [account, setAccount] = useState("0x");
    const { web3Api, setWeb3Api } = useContext(Web3Context);
    useEffect(() => {
        const getAccount = async () => {
            const accounts = await web3Api.web3.eth.getAccounts();
            setAccount(accounts[0]);
        };
        web3Api.web3 && getAccount();
    }, [web3Api.web3, shouldReload]);
    // useEffect(() => {
    //     const loadProvider = async () => {
    //         const provider = await detectEthereumProvider();

    //         if (provider) {
    //             provider.request({ method: "eth_requestAccounts" });
    //             setWeb3Api({
    //                 web3: new Web3(provider),
    //                 provider,
    //             });
    //         } else {
    //             console.error("Please, Install Metamask");
    //             // alert("MetaMask not detected. Please install MetaMask.");
    //         }
    //     };
    //     loadProvider();
    // }, [shouldReload]);
    const updateDetail = (field, newData) => {
        setData((prevDetail) => ({
            ...prevDetail,
            [field]: newData,
        }));
    };
    const handleBuy = async () => {
        if (web3Api.web3 == null) {
            toast.error("Please install Metamask");
            return;
        }
        const contractNFT = new web3Api.web3.eth.Contract(
            abiERC721,
            addressNFT
        );
        try {
            const contractKPET = new web3Api.web3.eth.Contract(
                abiERC20,
                addressKPET
            );
            const balanceToken20 = await contractKPET.methods
                .balanceOf(account)
                .call();
            const detailNFT = await contractNFT.methods
                .detailNFTs(dataTokenId.tokenId)
                .call();
            if (balanceToken20 < Number(dataTokenId.price) * 10 ** 9) {
                toast.error("Insufficient balance");
                return;
            }
            if (detailNFT.ownerNFT == account) {
                toast.error("Your are owner of NFT");
                return;
            }
            const transactionBuy = {
                from: account,
                to: addressNFT,
                data: contractNFT.methods.buy(dataTokenId.tokenId).encodeABI(),
            };
            const transactionApprove = {
                from: account,
                to: addressKPET,
                data: contractKPET.methods
                    .approve(addressNFT, balanceToken20)
                    .encodeABI(),
            };
            setLoading(true);
            const isApprove = await contractKPET.methods
                .allowance(account, addressNFT)
                .call();
            if (isApprove < 10 * 10 ** 18) {
                await web3Api.web3.eth.sendTransaction(transactionApprove);
            }
            await web3Api.web3.eth.sendTransaction(transactionBuy);
            toast.success("Buy successfully");
            updateDetail("active", "false");
            setLoading(false);
        } catch (error) {
            toast.error("Buy failed");
            console.log(error);
            setLoading(false);
        }
    };
    if (
        (dataTokenId.active == "false" || dataTokenId.level == "0") &&
        !loading
    ) {
        return <NotFound />;
    }
    return (
        <>
            {/* <CommonSection title="NFT detail" /> */}
            <section>
                <Container>
                    <Row>
                        <Col
                            lg="6"
                            md="6"
                            sm="6"
                            style={{ marginTop: "1.5rem" }}
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/img/pet${dataTokenId.ele}${dataTokenId.adult}.png`}
                                alt=""
                                className="w-100 single__nft-img"
                                style={
                                    gradientStyle[
                                        Number(dataTokenId.rarity) - 1
                                    ]
                                }
                            />
                        </Col>
                        <Col lg="6" md="6" sm="6">
                            <div className="single__nft__content ">
                                {/* <h2>{dataTokenId.title}</h2> */}
                                <div
                                    className="nft__creator d-flex gap-3 align-items-center"
                                    style={{ marginTop: "1.5rem" }}
                                >
                                    <div className="creator__detail">
                                        <p>Seller</p>
                                        <h6>
                                            {dataTokenId.ownerNFT.slice(0, 6) +
                                                "..." +
                                                dataTokenId.ownerNFT.slice(
                                                    38,
                                                    42
                                                )}
                                        </h6>
                                        <p>Token ID</p>
                                        <h6>{dataTokenId.tokenId}</h6>
                                        <p>Rarity</p>
                                        <h6>{rarityNFT[dataTokenId.rarity]}</h6>
                                        <p>Name</p>
                                        <h6>{eleNFT[dataTokenId.ele]}</h6>
                                        <p>Level</p>
                                        <h6>{dataTokenId.level}</h6>
                                        <p>Stage</p>
                                        <h6>
                                            {dataTokenId.adult == "1"
                                                ? "Child"
                                                : "Adult"}
                                        </h6>
                                        <p>Hash Base</p>
                                        <h6>{dataTokenId.hashBase}</h6>
                                        <p>Hash Power</p>
                                        <h6>{dataTokenId.hashPower}</h6>
                                    </div>
                                </div>
                                <p className="my-4">{dataTokenId.desc}</p>
                                <button
                                    onClick={handleBuy}
                                    className="singleNft-btn d-flex align-items-center gap-2 w-100"
                                >
                                    <i class="ri-shopping-bag-line"></i>
                                    <span>
                                        Buy{" "}
                                        {(dataTokenId.price / 10 ** 9).toFixed(
                                            2
                                        )}{" "}
                                        KPET
                                    </span>
                                </button>
                                {loading && <LoadingSpinner />}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <LiveAuction /> */}
        </>
    );
};

export default NftDetails;
